@font-face {
    font-family: 'montserrat';
    src: url('./assets/fonts/montserrat-black.woff2') format('woff2'),
    url('./assets/fonts/montserrat-black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('./assets/fonts/montserrat-extra-bold.woff2') format('woff2'),
    url('./assets/fonts/montserrat-extra-bold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('./assets/fonts/montserrat-bold.woff2') format('woff2'),
    url('./assets/fonts/montserrat-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('./assets/fonts/montserrat-semi-bold.woff2') format('woff2'),
    url('./assets/fonts/montserrat-semi-bold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'montserrat';
    src: url('./assets/fonts/montserrat-medium.woff2') format('woff2'),
    url('./assets/fonts/montserrat-medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'druk-cyr';
    src: url('./assets/fonts/druk-cyr-bold.woff2') format('woff2'),
    url('./assets/fonts/druk-cyr-bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'druk-cyr';
    src: url('./assets/fonts/druk-cyr-superItalic.woff2') format('woff2'),
    url('./assets/fonts/druk-cyr-superItalic.woff') format('woff');
    font-weight: 1000;
    font-style: italic;
}

@font-face {
    font-family: 'druk-cyr';
    src: url('./assets/fonts/druk-cyr-mediumItalic.woff2') format('woff2'),
    url('./assets/fonts/druk-cyr-mediumItalic.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'druk-wide';
    src: url('./assets/fonts/druk-wide-mediumItalic.woff2') format('woff2'),
    url('./assets/fonts/druk-cyr-mediumItalic.woff') format('woff');
    font-style: italic;
    font-weight: 500;
}

@font-face {
    font-family: 'druk-wide-cyr';
    src: url('./assets/fonts/druk-wide-cyr-boldItalic.woff2') format('woff2'),
    url('./assets/fonts/druk-wide-cyr-boldItalic.woff') format('woff');
    font-style: italic;
    font-weight: 700;
}

@font-face {
    font-family: 'druk-wide-cyr';
    src: url('./assets/fonts/druk-wide-cyr-heavyItalic.woff2') format('woff2'),
    url('./assets/fonts/druk-wide-cyr-heavyItalic.woff') format('woff');
    font-style: italic;
    font-weight: 900;
}

/*
    1. Use a more-intuitive box-sizing model.
  */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/*
  2. Remove default margin
*/
* {
    margin: 0;
}

/*
  Typographic tweaks!
  4. Add accessible line-height
  5. Improve text rendering
*/
body {
    line-height: 1.45;

    -webkit-font-smoothing: antialiased;
}

/*
  6. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
    display: block;
    width: 100%;
    height: 100%;
}

/*
  7. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
    font: inherit;
}

/*
  8. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
    overflow-wrap: break-word;
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a {
    color: inherit;
    text-decoration: none;
}

.no-scroll {
    overflow: hidden;
    height: 100%;
  }
